/**  =====================
      Custom css start
==========================  **/
body {
    font-family: $theme-font-family;
    // font-family: 'Helvetica-Neue-regular';
    font-size: $theme-font-size;
    color: $theme-font-color;
    font-weight: 400;
    // background: $theme-background;
    background: $primary-color;
    position: relative;
}

// button {
//     font-family: 'Airstrip';
// }

* {
    &:focus {
        outline: none;
    }
}

a {
    &:hover {
        outline: none;
        text-decoration: none;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $theme-heading-color;
    font-weight: 600;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1rem;
}

h6 {
    font-size: 0.875rem;
}

p {
    font-size: 13px;
}

b,
strong {
    font-weight: 600;
}

.img-radius {
    border-radius: 50%;
}

.page-header {
    background: transparent;
    margin-top: -140px;
    margin-bottom: 30px;

    h5 {
        margin-bottom: 15px;
        font-weight: 400;
        color: #fff;
    }

    .page-header-title + .breadcrumb {
        background: transparent;
        padding: 0;
        margin-bottom: 0;

        > .breadcrumb-item {
            line-height: 1;
            &:before{
                color: rgba(255,255,255,0.5);
            }
            a {
                color: #fff;
                font: {
                    weight: 400;
                }
            }

            &:last-child a {
                color: #fff;
                font-weight: 600;
            }
        }
    }

    &:not(.breadcumb-sticky) {
        .page-header-title + .breadcrumb {
            > .breadcrumb-item {
                a {
                    font: {
                        size: 13px;
                    }
                }
            }
        }
    }

    &.breadcumb-sticky {
        // padding: 15px 25px;
        // margin-top: -25px;
        // margin-left: -25px;
        // margin-right: -25px;
        // box-shadow: 0 0 3px 0 rgba(54, 80, 138, 0.3);
        overflow: hidden;
        position: relative;


        .page-header-title {
            display: inline-block;

            h5 {
                margin-bottom: 0;
                color: #fff;
            }
        }
        .breadcrumb-item + .breadcrumb-item::before,
        .page-header-title + .breadcrumb > .breadcrumb-item a,
        .page-header-title + .breadcrumb > .breadcrumb-item:last-child a{
            color: #fff;
        }

        .breadcrumb {
            display: inline-flex;
            float: right;
            margin-top: 4px;
            @media only screen and (max-width: 767px) {
                display: flex;
                float: none;
            }
        }
    }
}

.pcoded-main-container {
    .page-header {
        +.row{
            // margin-top: -35px;
        }
    }
}
/* ==========  card css start  =========== */

.anim-rotate {
    animation: anim-rotate 1s linear infinite;
}
@keyframes anim-rotate {
    100% {
        transform: rotate(360deg);
    }
}

.anim-close-card {
    animation: anim-close-card 1.4s linear;
}
@keyframes anim-close-card {
    100% {
        opacity: 0.3;
        transform: scale3d(.3, .3, .3);
    }
}
/* ==========  scrollbar End  =========== */

.scroll-y {
    z-index: 1027;
}
/* ==========  scrollbar End  ===========
 ==========  card css End  ===========
 ================================    Dropdown Start  ===================== */

.dropdown-menu {
    padding: 10px 0;
    // box-shadow: 0 0 4px 0 rgba(43, 43, 43, 0.25);
    // border: none;
    font-size: $theme-font-size;

    > li,
    > .dropdown-item {
        padding-left: 8px;
        padding-right: 8px;
        margin: 0 10px;
        width: auto;
        border-radius: 2px;

        > a {
            padding: 5px;
            color: $theme-font-color;

            i {
                font-size: 15px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
            }
        }

        &.active,
        &:active,
        &:focus,
        &:hover {
            background: $primary-color;
            color: #fff;
            box-shadow: 0 0 4px 0 rgba(43, 43, 43, 0.25);

            > a {
                background: transparent;
                color: #fff;
            }
        }
    }

    > a {
        &.active,
        &:active,
        &:focus,
        &:hover {
            background: $primary-color;
            color: #fff;
        }
    }
}

.drp-icon {
    &:after {
        display: none;
    }
}
/* ================================    Dropdown End  ===================== */
@media (min-width: 1600px) {
    .container {
        max-width: 1540px;
    }
}
@media (max-width: 992px) {
    .container {
        max-width: 100%;
    }
}
/* ========================================================
 ===============     document      ======================
 ========================================================

 Grid examples
*/

.bd-example-row {
    background: $theme-background;
    padding: 15px;
    margin-bottom: 15px;

    .row {
        > [class^="col-"],
         > .col {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            background-color: rgba(86, 61, 124, .15);
            border: 1px solid rgba(86, 61, 124, .2);
        }
    }

    .row + .row {
        margin-top: 1rem;
    }

    .flex-items-bottom,
    .flex-items-middle,
    .flex-items-top {
        min-height: 6rem;
        background-color: rgba(255, 0, 0, .1);
    }
}

.bd-example-row-flex-cols .row {
    min-height: 10rem;
    background-color: rgba(255, 0, 0, .1);
}
/* Docs examples */

.bd-example {
    position: relative;
    padding: 1rem;
    margin: 1rem;
    border: solid #ddd;
    border-width: 0.2rem 0 0;
    @media only screen and (max-height: 575px) {
        padding: 1.5rem;
        margin-right: 0;
        margin-left: 0;
        border-width: 0.2rem;
    }
}
/* Example modals */

.modal {
    z-index: 1072;

    .popover,
    .tooltip {
        z-index: 1073;
    }
}

.modal-backdrop {
    z-index: 1071;
}

.bd-example-modal {
    background-color: #fafafa;

    .modal {
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        z-index: 1;
        display: block;
    }

    .modal-dialog {
        left: auto;
        margin-right: auto;
        margin-left: auto;
    }
}
/* Example tabbable tabs */

.bd-example-tabs .nav-tabs {
    margin-bottom: 1rem;
}
/* Code snippets */

.highlight {
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #f3f2fb;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    @media only screen and (max-height: 575px) {
        padding: 1.5rem;
    }
}

.bd-content .highlight {
    margin-right: 15px;
    margin-left: 15px;
    @media only screen and (max-height: 575px) {
        margin-right: 0;
        margin-left: 0;
    }
}

.highlight {
    pre {
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        background-color: transparent;
        border: 0;
    }

    pre code {
        font-size: inherit;
        color: #333;
    }
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
    background: transparent;
}
/* =======================================================================
 ===============     Ui kit copy model style      ====================== */
/* Code examples */

.datta-example {
    position: relative;

    .datta-example-btns {
        position: absolute;
        right: 30px;
        top: -10px;
        opacity: 0;
        transform: rotateX(-90deg);
        transition: all 0.3s ease-in-out;

        .datta-example-btn {
            display: inline-block;
            line-height: 1;
            font-weight: 600;
            background: $primary-color;
            color: #fff;
            padding: 0.1875rem 0.3125rem;
            border-radius: 2px;
            white-space: nowrap;
            font-size: 11px;
            margin: 0 4px;

            &.copy::before {
                content: "COPY";
            }

            &.copied {
                background: $success-color !important;
                color: #fff !important;

                &::before {
                    content: "COPIED!";
                }
            }
        }
    }

    &:hover .datta-example-btns {
        top: -21px;
        transform: rotateX(0deg);
        opacity: 1;
    }
}
/* Modal */

.datta-example-modal {
    position: fixed;
    z-index: 1099;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #282c34;
    transform-origin: 50% 0;
    transform: scale(0.0);
    transition: all 0.3s ease-in-out;
}

.datta-example-modal-opened {
    overflow: hidden !important;

    .datta-example-modal {
        transform: scale(1);
    }
}

.datta-example-modal-content {
    max-width: 100vw;
    margin: auto;
    padding: 50px;
    height: 100vh;
    overflow: auto;

    > pre {
        overflow: hidden;
        width: fit-content;

        > code {
            padding: 0;
            background: none;
            font-size: 16px;
        }
    }
}

.md-datta-example-modal-copy {
    display: block;
    position: fixed;
    top: 90px;
    right: 30px;
    margin-right: 8px;
    font-size: 1.5rem;
    border-radius: 50%;
    padding: 10px 15px;
    background: $primary-color;
    color: #fff;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
    z-index: 1;
    white-space: nowrap;

    &:hover {
        box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
        color: #fff;
    }

    &.copied::before {
        content: "Copied to Clipboard Successfully ! . . .";
        position: absolute;
        display: block;
        right: 100%;
        margin-right: 10px;
        font-size: 14px;
        background: $success-color;
        line-height: 24px;
        height: 24px;
        border-radius: 3px;
        padding: 0 6px;
        top: 50%;
        margin-top: -12px;
    }
}

.datta-example-modal-close {
    display: block;
    position: fixed;
    top: 10px;
    right: 52px;
    color: #fff;
    opacity: 0.2;
    font-size: 3rem;
    font-weight: 100;
    transition: all 0.3s ease-in-out;
    z-index: 1;

    &:hover {
        color: #fff;
        opacity: 0.9;
    }
}
/* Code */
.cui-bottom-spacer {
    height: 12rem;
}
/* editor style for model */
.hljs {
    padding: 0.5em;
    color: #abb2bf;
    background: #282c34;
}

.hljs-comment,
.hljs-quote {
    color: #5c6370;
    font-style: italic;
}

.hljs-doctag,
.hljs-formula,
.hljs-keyword {
    color: #c678dd;
}

.hljs-deletion,
.hljs-name,
.hljs-section,
.hljs-selector-tag,
.hljs-subst {
    color: #e06c75;
}

.hljs-literal {
    color: #56b6c2;
}

.hljs-addition,
.hljs-attribute,
.hljs-meta-string,
.hljs-regexp,
.hljs-string {
    color: #98c379;
}

.hljs-built_in,
.hljs-class .hljs-title {
    color: #e6c07b;
}

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
    color: #d19a66;
}

.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
    color: #61aeee;
}

.hljs-emphasis {
    font-style: italic;
}

.hljs-strong {
    font-weight: bold;
}

.hljs-link {
    text-decoration: underline;
}
/* ================================    md animated mode start   ===================== */

.md-show.md-effect-12 ~ .md-overlay {
    background: $primary-color;
}
/* ================================    md animated mode End   ===================== */
/* ================================    range slider Start  ===================== */

.tooltip {
    &.in {
        opacity: 0.9;
    }

    .tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }

    &.top {
        padding: 5px 0;
        margin-top: -3px;

        .tooltip-arrow {
            bottom: 0;
            left: 50%;
            margin-left: -5px;
            border-width: 5px 5px 0;
            border-top-color: #000;
        }
    }
}
/* ================================    range slider End  ===================== */
/* ================================    owl-carousel slider Start  ===================== */

.owl-carousel {
    .owl-nav {
        button {
            &.owl-next,
            &.owl-prev {
                padding: 3px 9px;
            }
        }
    }

    button.owl-dot {}
}
/* ================================    owl-carousel slider End  ===================== */
/* ================================    Bootstrap tags input Start  ===================== */

.bootstrap-tagsinput {
    padding: 10px 15px;
    box-shadow: none;
    border-radius: 2px;
    background: $form-bg;

    .tag {
        padding: 5px 12px;
        border-radius: 2px;
        line-height: 37px;
        margin-top: 5px;
        margin-right: 5px;

        [data-role="remove"] {
            margin-right: -5px;

            &:after {
                content: "\e8f6";
                padding: 0 2px;
                font-family: 'feather' !important;
            }
        }
    }
}
/* ================================    Bootstrap tags input End  ===================== */
/* ================================    Multi-Select Start  ===================== */

.ms-container {
    width: 100%;
}
/* ================================    Multi-Select End  ===================== */
/* ================================    Blockquote Start  ===================== */

.blockquote {
    border-left: 0.25rem solid $theme-border;
    padding: 0.5rem 1rem;

    &.text-right {
        border-left: none;
        border-right: 0.25rem solid $theme-border;
    }
}
/* ================================    Blockquote End  ===================== */
/* ================================    animation start  ===================== */

.card .animated {
    animation-duration: 1s;
    border: 5px solid #fff;
    margin-bottom: 30px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}
/* ================================    animation End  ===================== */
/* ================================    browser  warning  Start  ===================== */

.ie-warning {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background: #000000;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    font-family: "Courier New", Courier, monospace;
    padding: 50px 0;

    p {
        font-size: 17px;
    }

    h1 {
        color: #fff;
    }

    .iew-container {
        min-width: 1024px;
        width: 100%;
        height: 200px;
        background: #fff;
        margin: 50px 0;
    }

    .iew-download {
        list-style: none;
        padding: 30px 0;
        margin: 0 auto;
        width: 720px;

        > li {
            float: left;
            vertical-align: top;

            > a {
                display: block;
                color: #000;
                width: 140px;
                font-size: 15px;
                padding: 15px 0;

                > div {
                    margin-top: 10px;
                }

                &:hover {
                    background-color: #eee;
                }
            }
        }
    }
}
/* ================================    browser  warning  End  ===================== */
div.code-toolbar {
    > .toolbar a,
     > .toolbar span,
    > .toolbar button {
        padding: 3px 0.9em !important;
        background: #04a9f5 !important;
        color: #fff !important;
        box-shadow: none !important;
    }
}

pre[class*=language-]:after,
pre[class*=language-]:before {
    display: none;
}
/* table css */

.table-borderless tbody tr td,
.table-borderless tbody tr th {
    border: 0;
}
@media only screen and (max-width: 400px) {
    .lay-customizer .theme-color > a[data-value="reset"],
    .theme-color > a[data-value="reset"] {
        margin-top: 20px;
    }
}
